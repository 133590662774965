<template>
  <div>
    <b-card>
      <b-col>
        <b-row align-h="center">
          <b-form-group
            v-if="dadosForm"
            label-cols-lg="12"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-row>
              <label><strong>CLIENTE:</strong></label>
              <b-col sm="8">
                <b-form-input
                  v-model="dados.cliente.nome"
                  size="sm"
                  disabled
                  class="mb-2 text-center"
                />
              </b-col>
            </b-row>
            <b-row>
              <label><strong>SINISTRO:</strong></label>
              <b-col sm="8">
                <b-form-input
                  v-model="dados.sinistro"
                  size="sm"
                  disabled
                  class="mb-2 text-center"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-row>
      </b-col>
      <UploadImages
        ref="uploadImages"
        clear-all="Limpar todas as imagens"
        upload-msg="Selecione as imagens"
      />
      <b-row
        align-h="center"
        class="mt-2"
      >
        <b-button
          variant="primary"
          @click="anexarArquivos"
        >
          Anexar
        </b-button>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BButton, BRow, BCol, BFormInput } from 'bootstrap-vue'
// eslint-disable-next-line
import UploadImages from 'vue-upload-drop-images'
import axios from 'axios'

export default {
  components: {
    BCard,
    UploadImages,
    BButton,
    BRow,
    BCol,
    BFormInput,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_ROOT_API,
      dados: {},
      id: '',
      dadosForm: false,
    }
  },
  created() {
    const url = new URL(window.location.href)
    const hash = url.pathname.split('/').pop()
    this.id = hash
    this.getDados(hash)
  },
  methods: {
    getDados(id) {
      axios.get(`${this.baseURL}/api/getDados/${id}`)
        .then(res => {
          this.dadosForm = true
          this.dados = res.data.dados
        })
    },
    anexarArquivos() {
      const formData = new FormData()
      const { files } = this.$refs.uploadImages

      for (let i = 0; i < files.length; i++) {
        formData.append(`arquivo${i}`, files[i])
      }
      axios.post(`${this.baseURL}/api/anexar/arquivos/${this.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Arquivo enviado',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          window.location.reload()
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: 'Ocorreu ao anexar os arquivos',
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>
